.main-header {
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--primary-color);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    padding: 0 1rem;
    z-index: 5;
  }
  
  main {
    margin-top: 5rem;
  }
  
  
  @media (max-width: 470px) {
    .main-header {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  
  }
  @media (max-width: 575px) {
    .main-header {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  
  }
  
  
  
  @media (min-width: 576px) {
    .main-header {
      flex-direction: row;
      justify-content: space-between;
  
    }
  }
  
  @media (min-width: 768px) {
    .main-header {
      justify-content: space-between;
    }
  }
  