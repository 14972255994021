.CheckoutForm__division-container{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
}

.CheckoutForm__payment-detail-container{
    display: flex;
    flex-direction: row;
    /* border: 2px solid blue; */
    /* padding-left: 2em; */
    /* padding-right: 2em; */
    padding-bottom: 0;
}
    .CheckoutForm__left-container{
        /* border: 3px solid purple; */
        width: 100%;
        /* margin-right: 3em; */
    }
    .CheckoutForm__right-container{
        /* border: 3px solid blue; */
        width: 100%;
        /* margin-right: 3em; */
    }
.CheckoutForm__btn-container{
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* margin-right: 3em ; */
    
}
    .CheckoutForm__btn{
        /* border: 3px solid black; */
        width: 48%;
        display:flex;
    flex-direction: row;
    justify-content: space-between;
    }

    .CheckoutForm__title{
        color: #00030A;
        font-family: Lato;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.25rem; 
        margin-bottom: .8em;
    }

.CheckoutForm__main-container{
    /* padding: 2.5rem 3.4375rem; */
    /* border: 3px solid red; */
    /* background-color: var(--primary-color); */
    width:100%;
}
.CheckoutForm__customer-data-form-container{
    padding: 2.5rem 3.4375rem;
    /* border: 3px solid red; */
    background-color: var(--primary-color); 
    width:100%;
}





/*NWWWWWWWWWWWWWWWW*/


.CheckoutForm__payment-detail-container{
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
}

    .CheckoutForm__payment-detail{
        /* border: 3px solid black; */
        width: 100%;
    }
        .PaymentDetail__form-container{
            height: 100%;
        }

    .CheckoutForm__product-detail{
        /* border: 8px solid yellow; */
        width: 100%;
    }
        .PaymentForm__subscription-card-container{
            /* border: 3px solid blue; */
        }
.CheckoutForm__customer-data-form-container{
    /* border: 3px solid blue; */
}

.CheckoutForm__orders-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-content: center;
    /* border: 3px solid red; */
}   
    .CheckoutForm__order{
        /* border: 3px solid green; */
        width: 100%;
    }
    .CheckoutForm__total{
        /* border: 3px solid blue; */
        width: 100%;
        border-top: 1px solid black ;
    }

.CheckoutForm__lbl-terms{
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
}
.CheckoutForm__total-container{
    width: 10em;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.CheckoutForm__crossed-out-price{
    text-decoration: line-through;
    color: #808080;
}
.CheckoutForm__price{
    font-weight: 700;
}

.CheckoutForm__form-container{
    padding: 2.5rem 3.4375rem;
    background-color:var(--primary-color);
    width:auto;
    height: 100%;
    padding-top: 5em;
}

.CheckoutForm__description h5{
    color: #00030A;
    font-family: "Montserrat", sans-serif;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem; /* 100% */
}
.CheckoutForm__description p{
    color: #000;
    font-family: "Lato", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: 2.25rem; 
}


 .CheckoutForm__payment-division-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} 

.CheckoutForm__total-container{
    
        width: 10em;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

}




.CheckoutForm__recaptcha-container{
    /* border: 3px solid green; */
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}


@media (max-width: 575.98px) {
    
    .CheckoutForm__payment-division-container{
        display: flex;
        flex-direction: column;
    }
    .CheckoutForm__division-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .CheckoutForm__btn-container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .CheckoutForm__btn{
            /* border: 3px solid black; */
            display:flex;
            flex-direction: row;
            width: 100%; 
        }
        .CheckoutForm__btn-container{
            padding-top: 1em;
            /* border: 3px solid red; */
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .CheckoutForm__btn{
            width: 70%;
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }



        /*========== new ========*/
        .CheckoutForm__division-container{
            flex-direction: column;
        }
        .CheckoutForm__payment-detail-container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
            .CheckoutForm__product-detail{
                order: 1;
            }
                .CheckoutForm__subscription-card-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    
                }
                    .CheckoutForm__terms-container{
                        margin-top: 5em;
                    }

            .CheckoutForm__payment-detail{
                order: 2;
            }
            .CheckoutForm__order{
                /* border: 3px solid green; */
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .CheckoutForm__total{
                /* border: 3px solid blue; */
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 2em;
            }
            .CheckoutForm__orders-container{
                width: auto;
                margin-top: .5em;

            }



}


@media (min-width: 576px) and (max-width: 767.98px) {
    .CheckoutForm__recaptcha-container{
        /* border: 3px solid green; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    
    .CheckoutForm__btn-container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
        .CheckoutForm__btn{
            /* border: 3px solid black; */
            display:flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%; 
        }
    .CheckoutForm__division-container{
        /* border: 2px solid white; */
        margin: 0;
        padding: 0;
    }
        .CheckoutForm__left-container{
            margin-right: 1.5em;
        }
        .CheckoutForm__right-container{
          
        }
        .CheckoutForm__btn-container{
            padding-top: 1em;
        }


         /*========== new ========*/
         .CheckoutForm__payment-detail-container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
            .CheckoutForm__product-detail{
                order: 1;
            }
                .CheckoutForm__subscription-card-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 4em;
                }
                    .CheckoutForm__terms-container{
                        margin-top: 2em;
                    }

            .CheckoutForm__payment-detail{
                order: 2;
            }
            .CheckoutForm__order{
                /* border: 3px solid green; */
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .CheckoutForm__total{
                /* border: 3px solid blue; */
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

}


@media (min-width: 768px) and (max-width: 991.98px) {
    .CheckoutForm__btn-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .CheckoutForm__left-container{
        margin-right: 2.5em;
    }
    .CheckoutForm__btn{
        width: 100%;
        justify-content: space-around;
    } 
    .CheckoutForm__btn-container{
        padding-top: 1em;
    }


    /*======new======*/
    .CheckoutForm__product-detail{
        /* border: 5px solid rgb(0, 0, 0); */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .CheckoutForm__subscription-card-container{
            /* border: 3px solid red; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-content: center;
            padding: 0;
            margin: 0;
        }
        .CheckoutForm__order{
            /* border: 3px solid green; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .CheckoutForm__total{
            /* border: 3px solid blue; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .CheckoutForm__terms-container{
            margin-top: 2em;
        }
   
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .CheckoutForm__left-container{
        margin-right: 1.5em;
    }
    .CheckoutForm__right-container{
      
    }

    /*======new======*/
    .CheckoutForm__product-detail{
        /* border: 8px solid rgb(0, 0, 0); */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .CheckoutForm__subscription-card-container{
            /* border: 3px solid red; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-content: center;
            padding: 0;
            margin: 0;
        }
        .CheckoutForm__order{
            /* border: 3px solid green; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .CheckoutForm__total{
            /* border: 3px solid blue; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .CheckoutForm__terms-container{
            margin-top: 2em;
        }

}

@media (min-width: 1200px) {
    .CheckoutForm__left-container{
        margin-right: 2.5em;
    }
    .CheckoutForm__btn-container{
        padding-top: 1em;
    }

    /*======new======*/
    .CheckoutForm__product-detail{
        /* border: 8px solid rgb(0, 0, 0); */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .CheckoutForm__subscription-card-container{
            /* border: 3px solid red; */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-content: center;
            padding: 0;
            margin: 0;
        }
        .CheckoutForm__order{
            /* border: 3px solid green; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .CheckoutForm__total{
            /* border: 3px solid blue; */
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .CheckoutForm__terms-container{
            margin-top: 0em;
        }
}