.NavegationInfo__section-container{
    /* border: 2px solid red; */
}
    .NavegationInfo__information{
        /* border: 3px solid green; */
        padding: 1em;
    }
    .NavegationInfo__information span{
        color: #000;
        font-family: Lato;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }